<template>
	<form class='a-booking-form-default' :class='{"a-booking-form-hotel-edit": edit}'>
		<div class='a-booking-form-row'>
			<div class="a-booking-form-col">
				<select-defaults
					:placeholder="$t('account.booking.content.country')"
					v-model='form.initialCountry'
					@input='updateCountry()'
                    initialLoading
                    :requestName='getAutocompleteCountries'
					:errors='!form.initialCountry && errors.country'
				/>
			</div>
			<div class="a-booking-form-col">
				<a-date-picker
					v-model='form.date'
					@change='changeDate($event)'
					:errors='!form.date && errors.dateFrom'
					:placeholder="`${$t('account.booking.content.date_of_the_trip')} ${$t('account.booking.content.there')} — ${$t('account.booking.content.back')}`"
					range
					right
					class='mobile-hide'
				/>
				<a-date-picker-mobile
					v-model='form.date'
					@change='changeDate($event)'
					:errors='!form.date && errors.dateFrom'
					:placeholder="`${$t('account.booking.content.date_of_the_trip')} ${$t('account.booking.content.there')} — ${$t('account.booking.content.back')}`"
					range
					class='mobile-show'
				/>
			</div>
			<div class="a-booking-form-col" v-if='!edit' :style='form.tourists && form.tourists.length ? "pointer-events: none; opacity: .5": ""'>
				<select-people
					:adults='form.adults'
					:childrenCount='form.childrenCount'
					:children='form.children'
					:placeholder='$t("account.booking.content.count_tourist")'
					@update-people='$event => form = {...form, ...$event}'
					compact
					:errors='!form.adults && errors.adults'
					:tourists='form.tourists'
				/>
			</div>
			<div class="a-booking-form-col">
				<a-autocomplete
					:placeholder="$t('account.home.hotels.nationality_tourists')"
					v-model='form.initialNationality'
					:requestName='getAutocompleteNationality'
					item-value='code'
					return-object
					actionInitial
                    initialLoading
                    append-icon='arrow-down'
					:errors='!form.initialNationality && errors.nationality'
				/>
			</div>
		</div>

<!--		<div class='a-booking-form-insurance-checkbox'>-->
<!--			<v-checkbox-->
<!--				name='insuranceYear'-->
<!--				:label="$t('account.booking.content.insurance_for_year')"-->
<!--				v-model='form.insuranceYear'-->
<!--				v-if='!edit'-->
<!--			/>-->
<!--		</div>-->

		<template v-if='!edit'>
			<select-tourist
				:initialTourists='form.tourists'
				@tourists='form.tourists = $event'
				@add-tourist='form.travelersIds = $event'
				@remove-tourist='form.travelersIds.splice($event, 1)'
			/>
		</template>

		<div class='row a-booking-form-action'>
			<div class="col">
				<template v-if='!edit'>
					<p class='a-booking-form-description' v-if='form.travelersIds.length >= 4'>{{$t("account.booking.content.max_number_tourists")}}</p>
				</template>
			</div>
			<div class="col-auto">
				<v-button :small='!edit' :xxsmall='edit' color='green white--text' @click='insuranceSearch' :loading='loading'>{{$t("account.booking.content.search")}}</v-button>
			</div>
		</div>
	</form>
</template>

<script>
	import SelectDefaults from "@/views/account/home/components/SelectDefaults";
	import SelectPeople from "@/views/account/home/components/SelectPeople";
	import SelectTourist from '@/views/account/home/components/SelectTourist';
	import { getAutocompleteCountries, getAutocompleteNationality, insuranceSearch } from '@/services/request';

	export default {
		data: () => ({
			form: {
				adults: 1,
				childrenCount: 0,
				children: [],
				travelersIds: [],
				initialCountry: null,
				country: null,
				date: null,
				dateFrom: null,
				dateTo: null,
				insuranceYear: false,
				resetCache: false,
				initialNationality: null,
				nationality: null
			},
			loading: false,
			errors: {}
		}),
		props: ["edit", "searchData", "initialTripName"],
		components: {
			SelectDefaults,
			SelectPeople,
			SelectTourist
		},
		computed: {
			local_search_data() {
				return localStorage.getItem('local_search_data') ? JSON.parse(localStorage.getItem('local_search_data')) : {};
			}
		},
		created() {
			if(this.local_search_data.insurance) {
				this.form = {...this.form, ...this.local_search_data.insurance};
			}
			if(this.$route.query.source) {
				this.form = JSON.parse(this.$route.query.source)
			} else if(this.searchData) {
				this.form = {...this.form, ...this.searchData};
			}
			if(this.form.initialCountry) {
				this.updateCountry();
			}
			this.tripName = this.$route.query?.tripName ?? this.initialTripName;

			if(this.form.tourists?.length === 0 && !this.edit) {
				this.form.travelersIds = [];
			}

			this.form.childrenCount = this.form.children.length
		},
		methods: {
			getAutocompleteCountries,
			getAutocompleteNationality,
			updateCountry() {
				this.form.country = this.form.initialCountry.code ?? null;
			},
			insuranceSearch() {
				if(this.$store.state.notification.isActive) this.$store.commit('notification/clearMessage');
				this.loading = true;
				let orderId = this.$route.params.orderId ? { orderId: this.$route.params.orderId } : false;

				insuranceSearch({...this.form, ...orderId}).then(res => {
					if(this.$route.params.storageId === res.data.storageId) {
						this.$emit("update");
					} else {
						this.$router.push({
							name: "insurance-search",
							params: {orderId: res.data.orderId, storageId: res.data.storageId, lang: this.$route.params.lang},
							query: {source: JSON.stringify(this.form), tripName: this.tripName}
						})
						localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, insurance: this.form}));
					}
				}).catch(err => {
					this.errors = err.response.data?.errors ?? {};
				}).finally(() => this.loading = false)
			},
			changeDate($event) {
				this.form.date = $event[0] ? $event : null;
				this.form.dateFrom = this.$moment($event[0]).format('YYYY-MM-DD');
				this.form.dateTo = this.$moment($event[1]).format('YYYY-MM-DD');
			}
		},
		watch: {
			"form.initialNationality"() {
				this.form.nationality = this.form.initialNationality?.code;
			},
			"form.tourists"() {
				localStorage.setItem('local_search_data', JSON.stringify({...this.local_search_data, insurance: this.form}));
			}
		}
	}
</script>

<style lang="scss">
	.a-booking-form-insurance-checkbox {
		margin-top: 12px;
	}
</style>
